import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../store/types'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthed = useSelector( state => state.user.isAuthed )
  if ( !isAuthed ) 
    dispatch({ type: types.APP_REDIRECT_TO, payload: path });

  return (
    <Route
      {...rest}
      render={props =>
        isAuthed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute