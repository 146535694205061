import { useEffect } from 'react';
import useReactRouter from 'use-react-router';

// Component that attaches scroll to top hanler on router change
const ScrollToTop = () => {
  const { location } = useReactRouter();
  // const mobileMenuIsOpen = useSelector( state => state.app.mobileMenuIsOpen )
  
  // just run the effect on pathname and/or search change
  useEffect(() => {
    //hide mobile menu if showing
    // if( mobileMenuIsOpen ) dispatch({ type: types.APP_TOGGLE_MOBILEMENU, payload: false });
    if( location.hash !== '' ) return undefined;
    
    window.scrollTo(0, 0);
    return undefined
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;