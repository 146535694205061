import React from 'react';
import cx from '../utils/styles'
import * as types from '../store/types'
import { useDispatch } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link';

const Year = ({ name, albums }) => {
  const dispatch = useDispatch();

  const selectYear = () => {
    dispatch({ type: types.GET_YEAR_ALBUM_FILE_DETAILS, payload: name });
  }
  
  return (
    <Link onClick={ selectYear } to={`/year/${name}`} className={cx( ' pa4 w-100 w-33-m w-25-l w-20-xl pointer year flex-column trans ' )} key={name} style={{height:'130px'}} >
      <div className={cx( ' white tc f6r lh-copy' )}>{name} </div>
      <div className={cx( ' tc lh-copy green ' )} style={{fontSize:'17px'}}>{ albums && `${albums.length } albums`} </div>
    </Link>
  );
}


export default Year