import React from 'react';
import { Year } from '../components';
import cx from '../utils/styles'
import { useSelector, useDispatch } from 'react-redux'
import * as types from '../store/types'

const Home = () => {
  const years = useSelector( state => state.view.yearsWithAlbumCount )
  const yearsLoading = useSelector( state => state.view.yearsLoading )
  const dispatch = useDispatch()

  if( !yearsLoading ) {
    dispatch({ type: types.GET_YEARS });
  }

  return (
    <>
      <div className={cx( ' container flex flex-wrap ' )} >
        { years && years.map( y => {
          return (
            <Year key={y.year} name={y.year} albums={y.albums}/>
          )
        })}
      </div>
    </>
  );
}

export default Home