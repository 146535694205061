import React from 'react';
import { Route, Switch } from "react-router-dom";
import ScrollToTop from './utils/scrollToTop'
import routes from './routes'
import { Header, SearchPanel, Alerts } from './components'
import cx from './utils/styles';
import PrivateRoute from './utils/PrivateRoute'

export default function App() {
  return (
    <div className={cx( ' font-adjust ' )} >
      <ScrollToTop/>
      <Alerts/>
      <Header/>
      <SearchPanel/>
      <div className={cx( ' ' )} style={{height:'8rem'}}/>
      <Switch>
        { routes.map( route => {
            if ( !route.private )
              return (<Route key={ route.path } { ...route } />)
            else
              return (<PrivateRoute key={ route.path } { ...route } />)
         } ) }
      </Switch>
    </div>
  )
}


