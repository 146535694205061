import React from 'react';
import { useSelector } from 'react-redux'
import cx from '../utils/styles'
import moment from 'moment'
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';

import * as types from '../store/types'
import { DatePicker, Button, AutoComplete, Select, message} from "antd";
const { Option } = Select;

/*
z-9999 to keep under Landbot popup
*/


const Search = () => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const showPanel = useSelector( state => state.view.isSearchPanelOpen )
  const albumNames = useSelector( state => state.view.albumNames )
  const peopleNames = useSelector( state => state.view.peopleNames )
  const searchCriteria = useSelector( state => state.view.searchedCriteria )

  if( albumNames.length === 0 ) {
    dispatch({ type: types.GET_ALL_ALBUM_NAMES })
  }

  const optionsForSelect = [];
  if( peopleNames.length === 0 ) {
    dispatch({ type: types.GET_ALL_PEOPLE_NAMES })
  } else {
    for (let i = 0; i < peopleNames.length; i++) {
      optionsForSelect.push(<Option key={peopleNames[i]}>{peopleNames[i].replace(/\./g, ' ')}</Option>)
    }
  }



  const searchInputs = () => {
    // const searchCriteria = {}
    const onNameChange = ( e ) => {
      //string
      searchCriteria.albumCol = e ? [e] : []
      saveSearchCriteria()
    }
    const onPeopleChange = ( e ) => {
      //array of uids
      searchCriteria.uidCol = e
      saveSearchCriteria()
    }
    const onDateFromChange = ( e ) => {
      //moment
      searchCriteria.albumDateFrom = e ? e.format('YYYY-MM-DD') : null
      saveSearchCriteria()
    }
    const onDateToChange = ( e ) => {
      //moment
      searchCriteria.albumDateTo = e ? e.format('YYYY-MM-DD') : null
      saveSearchCriteria()
    }
    const search = () => {
      if( searchCriteria.albumCol.length === 0 && searchCriteria.uidCol.length === 0 && searchCriteria.albumDateFrom == null && searchCriteria.albumDateTo == null ) {
        message.error("Enter at least one search criteria")
        return
      }
      history.push('/search')
      dispatch({ type: types.GET_FILES_FROM_SEARCH, payload: searchCriteria });
      close()
    }
    const close = () => {
      dispatch({ type: types.TOGGLE_SEARCH});
    }
    const saveSearchCriteria = () => {
      dispatch({ type: types.STORE_SEARCH_CRITERIA, payload: searchCriteria });
    }

    console.log('searchCriteria',searchCriteria)
    return (
      <div className={cx(` w-100 `)} style={{height:'300px'}}>
        <div className={cx(` w-50 mha mt6 `)} >
          <div className={cx(` flex flex-wrap `)} >
            <div className={cx(` w-100 w-50-ns `)} >
              <AutoComplete
                style={{ width: '100%' }}
                allowClear
                defaultValue={searchCriteria.albumCol && searchCriteria.albumCol.length > 0 ? searchCriteria.albumCol[0] : null}
                dataSource={ albumNames }
                onChange={onNameChange}
                placeholder="Album name"
                filterOption={(inputValue, option) =>
                  option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
            <div className={cx(` w-100 w-50-ns `)} >
              <Select
                defaultValue={searchCriteria.uidCol}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="People"
                onChange={onPeopleChange}
              >
                {optionsForSelect}
              </Select>
            </div>
          </div>
          <div className={cx(` flex flex-wrap `)} >
            <div className={cx(` w-100 w-50-ns `)} >
              <DatePicker
                defaultValue={ searchCriteria.albumDateFrom ? moment(searchCriteria.albumDateFrom,"YYYY-MM-DD") : null}
                format="YYYY-MM-DD"
                placeholder="From date"
                style={{ width: '100%' }}
                onChange={onDateFromChange}
              />
            </div>
            <div className={cx(` w-100 w-50-ns `)} >
              <DatePicker
                defaultValue={ searchCriteria.albumDateTo ? moment(searchCriteria.albumDateTo,"YYYY-MM-DD") : null}
                format="YYYY-MM-DD"
                placeholder="To date"
                style={{ width: '100%' }}
                onChange={onDateToChange}
              />
            </div>
          </div>
          <Button 
            onClick={search}
            type="primary"
            icon="search"
            style={{ width: 170, float: 'right', marginLeft: 10, marginTop: 10, backgroundColor: 'VAR(--coral)'}}>
            Search
          </Button>
          <Button 
            onClick={close}
            type="primary"
            icon="close"
            style={{ width: 170, float: 'right', marginTop: 10, backgroundColor: '#1F1F1F' }} >
            Close
          </Button>
        </div>
      </div>
    )
  }
  const search = showPanel ? searchInputs() : null

  return (
    <div className={cx(` bg-green w-100 overflow-y-hidden fixed top-0 trans z-999 `)} >
    { search}
    </div>
  )
}

export default Search;