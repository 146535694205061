import React from 'react';
import cx from '../../utils/styles'
import { FileForBrowse } from '../../components';
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect';
import { Button} from 'antd'

const Day = ({ date, files }) => {
  // console.log(date)
  // const file = React.useMemo( () => <FileForBrowse key={f.id} date={ date } file={f} />, [files])

  // const dispatch = useDispatch();
  // const reorderMode = useSelector( state => state.view.reorderMode )
  const dateAsMoment = moment(date,"YYYY-MM-DD")
  
  return (
    <div className={cx( ' flex flex-column mv2 ' )}>
       <div className={cx( ' white f2r lh-copy' )}>{ dateAsMoment.format("MMMM Do, YYYY") } </div>
       <div className={cx( ' flex flex-wrap ' )} >
        {  files && files.map( f => {
            return (
              <FileForBrowse key={f.id} date={ date } file={f} />
            )
          })
        }
      </div>
    </div>
  );
}




export default Day