import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer'
import { lambdaMiddleware } from '../utils/lambdaMiddleware'
import { netlifyAuthMiddleware } from '../utils/netlifyAuth'
import { notificationMiddleware } from '../utils/notificationMiddleware'

const composeEnhancers = composeWithDevTools({
  trace: true, 
  traceLimit: 25
});

export function makeStore() {
  return createStore(
    reducer,
    composeEnhancers (
      applyMiddleware ( lambdaMiddleware, netlifyAuthMiddleware, notificationMiddleware )
    )
  )
}