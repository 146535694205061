import React from 'react';
import { File } from '../components';
import cx from '../utils/styles'
import { useSelector, } from 'react-redux'

const Search = () => {
  const searchedFiles = useSelector( state => state.view.searchedFiles )
  const searching = useSelector( state => state.view.searching )
  
  if( !searching && searchedFiles.length === 0) {
    //send request to get files if page loaded here from link
    // const date = location.pathname.split("/")[2].substr(0,10)
    // const year = date.substr(0,4)
    // const name = location.pathname.split("/")[2].substr(11)
    // dispatch({ type: types.GET_YEAR_ALBUM_FILE_DETAILS, payload: year });
    // dispatch({ type: types.GET_ALBUM_FILES, payload: { name, date }})
  }
  
  return (
    <>
    {/* <div className={cx( ' bg-grey f5r white tc lh-copy mt6 ml3 ' )}>{album && album.name}</div>
    <div className={cx( ' bg-grey f3r green tc lh-title mb3 ml3 ' )}>{album && moment(album.date).format('MMM-DD YYYY')}</div> */}
    <div className={cx( ' bg-grey horizontal-scroll-wrapper flex flex-nowrap overflow-x-auto flex-column flex-row-ns ' )} >
      { searchedFiles.length > 0 && searchedFiles.map( f => {
        return (
          <File key={`${f._id}`} file={f}/>
        )
      })}
    </div>
    </>
  );
}

export default Search