
import React, { useState, useRef } from 'react';
import cx from '../../utils/styles'
import { useSelector, useDispatch } from 'react-redux'
import Vimeo from '@u-wave/react-vimeo';
import { isMobile } from 'react-device-detect';
import { Icon } from 'antd'
import * as types from '../../store/types'
import download from 'downloadjs'
import ReactPlayer from 'react-player'
import renderIf from 'render-if'
import { Button} from 'antd'

const FileForBrowse = React.memo(({ date, file }) => {
  const dispatch = useDispatch();
  const fileHeight = '180px'

  // console.log('render', file.id)

  const [ selected, setSelected ] = useState(false)
  // const fileRef = useRef(null);

  const selectFileToggle = () => {
    if( selected ){
      setSelected(false)
      dispatch({ type: types.FILE_DESELECTED, payload: { date, file }});
    } else {
      setSelected(true)
      dispatch({ type: types.FILE_SELECTED, payload: { date, file }});
    }
  }

  const Image = () => {
    return (
      <>
        <img onClick={ selectFileToggle }  className={cx( 'w-auto pointer ' )} style={{height: isMobile ? 'auto' : fileHeight, width: isMobile ? '100%' : 'auto'}} src={ file.url }  title={ '' } alt={ '' } />
      </>
    )
  }

  const Video = React.memo(() => {
    return (
      <>
        <ReactPlayer 
          onClick={ selectFileToggle }
          url={ file.url }
          controls
          height={ isMobile ? 'auto' : fileHeight }
          width={ isMobile ? window.innerWidth-16 : 'auto' }
        />
      </>
    )
  },[])

  return (
    <div className={cx( ' pa3 relative flex-column mv2 mv0-ns lh-0' )}>
      { renderIf( file.type === 'jpg' || file.type === 'png' )(
        <Image/>
      )}
      { renderIf( file.type !== 'jpg' && file.type !== 'png' )(
        <Video/>
      )}
      { renderIf( selected )(
       <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ top: 10, right: 10 }} className={cx('f3r absolute')} />
      )}
    </div>
  );
})


export default FileForBrowse