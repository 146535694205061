import { useDispatch, useSelector } from 'react-redux';
import * as types from '../store/types';
import netlifyIdentity from 'netlify-identity-widget';
import useReactRouter from 'use-react-router';

const devUser = {
  user_metadata : {
    full_name: 'Dev USER'
  },
  app_metadata : {
    roles:['Admin']
  }
}

const useNetlifyAuth = ( ) => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const user = useSelector( state => state.user.user )

  // const checkAuth = () =>{
  //   const user = process.env.NODE_ENV === 'development' ? devUser : netlifyIdentity.currentUser()
  //   if( user !== null ) {
  //     dispatch({ type: types.USER_AUTHED, payload: user });
  //     return true
  //   } else return false
  // }

  const login = () => {
    if( process.env.NODE_ENV === 'development' ) 
      dispatch({ type: types.USER_AUTHED_FROM_LOGIN , payload: devUser });
    else
      netlifyIdentity.open();
  }

  const logout = () => {
    if( process.env.NODE_ENV === 'development' ) {
      dispatch({ type: types.USER_LOGGEDOUT })
      history.push('/')
    }
    else
      netlifyIdentity.logout();
  }

  const isAdmin = user && user.app_metadata.roles.includes('Admin')
  
  netlifyIdentity.on('login', user => {
    dispatch({ type: types.USER_AUTHED_FROM_LOGIN , payload: user });
  });

  netlifyIdentity.on('logout', () => {
    dispatch({ type: types.USER_LOGGEDOUT })
    history.push('/')
  });

  return { login, logout, isAdmin };
}

export default useNetlifyAuth; 