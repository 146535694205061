import React from 'react';
import { Album } from '../components';
import cx from '../utils/styles'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';
import * as types from '../store/types'
import moment from 'moment'

const Year = () => {
  const dispatch = useDispatch();
  const albums = useSelector( state => state.view.albumsWithFiles )
  const yearLoading = useSelector( state => state.view.yearLoading )
  const { location } = useReactRouter();
  
  if( yearLoading === null && albums === null ) {
    console.log('year container thinks albums is null', location.pathname.split("/")[2])
    //send request to get files if page loaded here from link
    dispatch({ type: types.GET_YEAR_ALBUM_FILE_DETAILS, payload: location.pathname.split("/")[2] });
  }

  const displayYear = albums !== null && albums.length > 0 ? moment(albums[0].date).format('YYYY') : yearLoading
  const LoadingContent = () => {
    if( albums == null )
      return (
        <div className={cx(' mha f2r white ')}>loading...</div>
      )
    else
      return null
  }

  return (
    <>
    <div className={cx( ' bg-grey f5r white tc lh-title mt3 mb3 ml3 ' )}>{ displayYear }</div>
    <div className={cx( ' container flex flex-wrap trans ' )} >
      <LoadingContent/>
      { albums && albums.map( a => {
        return (
          <Album key={`${a.date}${a.name}`} date={a.date} name={a.name} files={a.files} cover={a.cover} _id={a._id}/>
        )
      })}
    </div>
    </>
  );
}

export default Year