const GET_YEARS = "app/GET_YEARS";
const GET_YEAR_ALBUM_COUNT = "app/GET_YEAR_ALBUM_COUNT";
const GET_YEAR_ALBUM_FILE_DETAILS = "app/GET_YEAR_ALBUM_FILE_DETAILS";
const GET_ALBUM_FILES = "app/GET_ALBUM_FILES";
const GET_FILES_FROM_SEARCH = "app/GET_FILES_FROM_SEARCH"; 
const GET_ALL_ALBUM_NAMES = "app/GET_ALL_ALBUM_NAMES"; 
const GET_ALL_PEOPLE_NAMES = "app/GET_ALL_PEOPLE_NAMES"; 

const ALBUM_SELECTED = "app/ALBUM_SELECTED"; 

const STORE_YEARS = "app/STORE_YEARS";
const STORE_YEAR_ALBUM_COUNT = "app/STORE_YEAR_ALBUM_COUNT";
const STORE_ALBUM_FILES = "app/STORE_ALBUM_FILES";
const STORE_YEAR_ALBUMS_FILES = "app/STORE_YEAR_ALBUMS_FILES";
const STORE_ALBUM_NAMES = "app/STORE_ALBUM_NAMES";
const STORE_ALL_PEOPLE_NAMES = "app/STORE_ALL_PEOPLE_NAMES"; 
const STORE_SEARCH_FILES = "app/STORE_SEARCH_FILES"
const STORE_SEARCH_CRITERIA = "app/STORE_SEARCH_CRITERIA"

const UPDATE_ALBUM = "UPDATE_ALBUM"
const UPDATED_ALBUM = "UPDATED_ALBUM"
const SET_REORDER_MODE = "SET_REORDER_MODE"
const SAVE_NEW_ALBUM = "SAVE_NEW_ALBUM"
const NEW_ALBUM_SAVED = "NEW_ALBUM_SAVED"

const TOGGLE_MOBILEMENU = "app/TOGGLE_MOBILEMENU"
const TOGGLE_SEARCH = "app/TOGGLE_SEARCH"

const APP_REDIRECT_TO = "app/APP_REDIRECT_TO"
const APP_REDIRECT = "app/APP_REDIRECT"
const USER_AUTHED = "app/USER_AUTHED"
const USER_AUTHED_FROM_LOGIN = "app/USER_AUTHED_FROM_LOGIN"
const IS_USER_AUTHED = "app/IS_USER_AUTHED"
const NETLIFY_AUTH_INIT = "app/NETLIFY_AUTH_INIT"
const USER_LOGGEDOUT = "app/USER_LOGGEDOUT"

const SET_ALERT_OR_MESSAGE = "app/SET_ALERT_OR_MESSAGE"
const UNSET_ALERT_OR_MESSAGE = "app/UNSET_ALERT_OR_MESSAGE"
const SEND_NOTIFICATION = "SEND_NOTIFICATION"

/* browse */
const GET_FILES_FOR_BROWSE = "GET_FILES_FOR_BROWSE"
const STORE_FILES_FOR_BROWSE = "STORE_FILES_FOR_BROWSE"
const FILE_SELECTED = "FILE_SELECTED"
const FILE_DESELECTED = "FILE_DESELECTED"
const FILE_DELETE = "FILE_DELETE"
const FILE_DELETED_SUCCESS = "FILE_DELETED_SUCCESS" 
const FILE_DELETED_FAIL = "FILE_DELETED_FAIL" 

export {
  TOGGLE_MOBILEMENU,
  TOGGLE_SEARCH,
  GET_YEARS,
  GET_YEAR_ALBUM_COUNT,
  GET_YEAR_ALBUM_FILE_DETAILS,
  GET_FILES_FROM_SEARCH,
  GET_ALBUM_FILES,
  GET_ALL_ALBUM_NAMES,
  GET_ALL_PEOPLE_NAMES,
  STORE_YEARS,
  STORE_YEAR_ALBUM_COUNT,
  STORE_ALBUM_FILES,
  STORE_YEAR_ALBUMS_FILES,
  STORE_ALBUM_NAMES,
  STORE_ALL_PEOPLE_NAMES,
  STORE_SEARCH_FILES,
  STORE_SEARCH_CRITERIA,
  ALBUM_SELECTED,
  APP_REDIRECT_TO,
  APP_REDIRECT,
  NETLIFY_AUTH_INIT,
  USER_AUTHED,
  USER_AUTHED_FROM_LOGIN,
  IS_USER_AUTHED,
  USER_LOGGEDOUT,
  UPDATE_ALBUM,
  UPDATED_ALBUM,
  SET_ALERT_OR_MESSAGE,
  UNSET_ALERT_OR_MESSAGE,
  SET_REORDER_MODE,
  SAVE_NEW_ALBUM,
  NEW_ALBUM_SAVED,
  STORE_FILES_FOR_BROWSE,
  GET_FILES_FOR_BROWSE,
  FILE_SELECTED,
  FILE_DESELECTED,
  FILE_DELETE,
  FILE_DELETED_SUCCESS,
  FILE_DELETED_FAIL,
  SEND_NOTIFICATION,
}