import { combineReducers } from 'redux'
import * as types from "./types";
import { escape } from 'lodash';
import moment from 'moment'
import _ from 'lodash'

const updateArrayImmutable = (arr, i, value) => 
  Object.assign([...arr], {[i]: value})

const viewInitial = {
  yearsWithAlbumCount: null,
  albumsWithFiles: null,
  displayAlbumWithFiles: null,
  yearLoading: null,
  yearsLoading: false,
  mobileMenuIsOpen: false,
  isSearchPanelOpen: false,
  albumNames:[],
  peopleNames:[],
  searchedFiles: [],
  searching: false,
  searchedCriteria:{
    albumCol:[],
    uidCol:[],
    albumDateFrom:null,
    albumDateTo:null
  },
  alertOrMessage: null,
  reorderMode:false,
  albumNeedsSaving: false,
  filesForBrowse: null,
  loadingFilesForBrowse: false,
  filesSelected: [],
}
const viewReducer = ( state = viewInitial, action ) => {
  if (!state) {
    return null;
  }

  switch( action.type ) {
    case types.SET_REORDER_MODE: {
      return { ...state,
        reorderMode : !state.reorderMode,
      }
    }
    case types.SET_ALERT_OR_MESSAGE: {
      return { ...state,
        alertOrMessage : action.payload,
      }
    } 
    case types.UNSET_ALERT_OR_MESSAGE: {
      return { ...state,
        alertOrMessage : null,
      }
    } 
    case types.TOGGLE_MOBILEMENU: {
      return { ...state,
        mobileMenuIsOpen : action.payload,
      }
    } 
    case types.TOGGLE_SEARCH: {
      return { ...state,
        isSearchPanelOpen : !state.isSearchPanelOpen,
      }
    } 
    case types.GET_YEARS: {
      return { ...state,
        yearsLoading : true,
      }
    }
    case types.STORE_YEARS: {
      /* PAYLOAD
        { 
          year: 1997,
          albums: [ null ]
        }
      */
      return { ...state,
        yearsWithAlbumCount : action.payload
      }
    }
    case types.STORE_YEAR_ALBUM_COUNT: {
      /* PAYLOAD
        { 
          year: 1997,
          albums: [ albums ]
        }
      */
      const index = state.yearsWithAlbumCount.findIndex( e => e.year === action.payload.year);
      return { 
        ...state,
        yearsWithAlbumCount : updateArrayImmutable( state.yearsWithAlbumCount, index, { year: action.payload.year, albums: action.payload.albums }),
      }
    }
    case types.STORE_ALBUM_NAMES: {
      /* PAYLOAD
        [ name1, name 2, etc ]
      */
      return { 
        ...state,
        albumNames : action.payload
      }
    }
    case types.STORE_ALL_PEOPLE_NAMES: {
      /* PAYLOAD
        { 
          uid:p.uid,
          msFacePersonId:p.msFacePersonId 
        }
      */
      return { 
        ...state,
        peopleNames :  action.payload.map( p => p.uid )
      }
    }
    case types.STORE_ALBUM_FILES: {
      /* PAYLOAD
        { 
          name: Hullo Be,
          date: 2018-06-06,
          cover: urlToFile,
          files: [ files ],
          _id: string
        }
      */
      return {
        ...state,
        displayAlbumWithFiles : action.payload
      }
    }
    case types.GET_YEAR_ALBUM_FILE_DETAILS:{
      return {
        ...state,
        albumsWithFiles: null,
        yearLoading: action.payload,
      }
    }
    case types.STORE_YEAR_ALBUMS_FILES: {
      /* PAYLOAD
        [
          { 
            name: Hullo Be,
            date: 2018-01-01,
            cover: urlToFile,
            files: [ files ],
            _id: string
          }
        ]
      */
      return {
        ...state,
        albumsWithFiles : action.payload,
      }
    }
    case types.ALBUM_SELECTED:{
      /* PAYLOAD
        [
          { 
            name: Hullo Be,
            date: 2018-01-01,
            files: [ files ],
            _id: string,
            cover: url
          }
        ]
      */
      return {
        ...state,
        displayAlbumWithFiles: action.payload,
      }
    }
    case types.GET_FILES_FROM_SEARCH: {
      return {
        ...state,
        searching : true,
      }
    }    
    case types.STORE_SEARCH_FILES: {
      /* PAYLOAD
        [ {
            urlOrig: mFile.flickrOrigUrl,
            url: mFile.flickrWsUrl,
            taken: moment( mFile.taken).format('YYYY-MM-DD'), 
            album: mAlbum.name,
            albumDate: moment( mAlbum.albumDate ).format('YYYY-MM-DD'), 
            filename: mFile.filename,
            wsWidth: mFile.width,
            wsHeight: mFile.height,
        } ]
      */
      return {
        ...state,
        searchedFiles : action.payload,
        searching: false
      }
    }    
    case types.STORE_SEARCH_CRITERIA: {
      /* PAYLOAD
        {
          albumName:null,
          uids:[],
          albumDateFrom:null,
          albumDateTo:null
        }
      */
      return {
        ...state,
        searchedCriteria : action.payload
      }
    }
    case types.UPDATE_ALBUM:{
      if ( action.payload.newAlbum !== undefined || action.payload.newAlbum === true ) {
        const newAlbum = {
          _id: 'newAlbum_noId',
          name: action.payload.name,
          date: action.payload.date,
          cover: 'noCoverURLYet',
          files: action.payload.files
        }
  
        return {
          ...state,
          displayAlbumWithFiles : newAlbum,
        }
      }
      return {
        ...state,
        albumNeedsSaving: true,
      }
    }
    case types.UPDATED_ALBUM: {
      /* PAYLOAD
        {
          _id: string,
          action: name/date/files
          name:,
          date:,
          files:,
          cover:,
        }
      */  
      const newAlbum = {
        _id: action.payload._id,
        name: action.payload.name,
        date: action.payload.date,
        cover: action.payload.cover,
        files: action.payload.files
      }

      const index_album = state.albumsWithFiles.findIndex( e => e._id === action.payload._id);

      return {
        ...state,
        displayAlbumWithFiles : newAlbum,
        albumsWithFiles: updateArrayImmutable( state.albumsWithFiles, index_album, newAlbum )
      }
    }
    case types.NEW_ALBUM_SAVED: {
      //will be called once backend has saved album in Mongo

      /* PAYLOAD
        {
          _id: string,
          action: name/date/files
          name:,
          date:,
          files:,
        }
      */  
      return {
        ...state,
        albumNeedsSaving: false,
        displayAlbumWithFiles : action.payload,
        albumsWithFiles: updateArrayImmutable( state.albumsWithFiles, state.albumsWithFiles.length, action.payload ),
      }
    }
    case types.GET_FILES_FOR_BROWSE:{
      return {
        ...state,
        loadingFilesForBrowse: true,
      }
    }
    case types.STORE_FILES_FOR_BROWSE : {
      //create urls
      /*
        {
          files: files,
          page: result.photos.page,
          perpage: result.photos.perpage,
          total: result.photos.total,
        }

      */
      const groupedByDate =  _.chain(action.payload.files)
        .groupBy("takenDateAsKey")
        .map((value, key) => ({ date: key, files: value }))
        .value()
      const objectByDate =  _.chain(groupedByDate)
        // Group the elements of Array based on `takenDate` property
        .keyBy("date")
        // `key` is group's name (date), `value` is the array of objects
        .mapValues('files')
        .value()

      return {
        ...state,
        filesForBrowse: objectByDate,
        loadingFilesForBrowse: false,
      }
    }
    case types.FILE_SELECTED: {
      return {
        ...state,
        filesSelected: [...state.filesSelected, action.payload.file]
      }
      // const k = `date${action.payload.date}`
      // console.log(action.payload, k)
      // const newFile = { ...action.payload.file, selected: !action.payload.file.selected }
      // const index = state.filesForBrowse[k].findIndex( f => f.id === newFile.id );
      // return {
      //   ...state,
      //   filesForBrowse: {
      //     ...state.filesForBrowse,
      //     [k]: updateArrayImmutable( state.filesForBrowse[k], index, newFile ),
      //   }
      // }
    }
    case types.FILE_DESELECTED: {
      return {
        ...state,
        filesSelected: state.filesSelected.filter( f => f.id != action.payload.file.id )
      }
      // const k = `date${action.payload.date}`
      // console.log(action.payload, k)
      // const newFile = { ...action.payload.file, selected: !action.payload.file.selected }
      // const index = state.filesForBrowse[k].findIndex( f => f.id === newFile.id );
      // return {
      //   ...state,
      //   filesForBrowse: {
      //     ...state.filesForBrowse,
      //     [k]: updateArrayImmutable( state.filesForBrowse[k], index, newFile ),
      //   }
      // }
    }
    case types.FILE_DELETED_SUCCESS: {
      console.log('FILE_DELETED_SUCCESS', action.payload)
      //return state.filter( item=> item != state[action.index]  )
      const index = state.filesForBrowse[action.payload.takenDateAsKey].findIndex( f => f.id === action.payload.file );
      return {
        ...state,
        filesSelected: state.filesSelected.filter( f => f.id != action.payload.file ),
        filesForBrowse: {
          ...state.filesForBrowse,
          [action.payload.takenDateAsKey]: state.filesForBrowse[action.payload.takenDateAsKey].filter( f => f.id !== action.payload.file)
        }
      }
    }
    default: return state;
  }
}


const userInitial ={
  isAuthed: false,
  redirect: false,
  redirectTo: null,
  user: null,
}
const userReducer = ( state = userInitial, action ) => {
  if (!state) {
    return null;
  }

  switch( action.type ) {
    case types.USER_AUTHED_FROM_LOGIN: {
      return { ...state,
        isAuthed: true,
        redirect: true,
        user: action.payload,
      }
    }
    case types.USER_AUTHED: {
      return { ...state,
        isAuthed: true,
        user: action.payload,
      }
    }
    case types.APP_REDIRECT_TO: {
      return { ...state,
        redirectTo: action.payload,
      }
    }
    case types.USER_LOGGEDOUT: {
      return { ...state,
        user: null,
        isAuthed: false,
        redirect: false,
      }
    }
    default: return state;
  }
}


const reducer = combineReducers( {
  user: userReducer,
  view: viewReducer,
} );

export default reducer;
