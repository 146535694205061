import { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../store/types'

const useBrowse = ( ) => {
  const globalDispatch = useDispatch();
  const loadingFiles = useSelector( state => state.view.loadingFilesForBrowse )
  const groupedFiles = useSelector( state => state.view.filesForBrowse )
  const filesSelected = useSelector( state => state.view.filesSelected )

  useEffect(() => {
    if( groupedFiles ) {
      dispatch({type:"STORE",payload:groupedFiles})
    }
  },[groupedFiles])

  useEffect(() => {
    if( !loadingFiles ) {
      globalDispatch({type:types.GET_FILES_FOR_BROWSE})
    }
  },[])

  const createDateArray = ( numberOfPages, pagedFiles ) => {
    const dateArray = []
    for( let i=0; i<numberOfPages; i+=1 ){
      pagedFiles[i].map( d => {
        dateArray.push(d)
      })
    }
    return dateArray
  }
  const chunkArray = (arrayToBreakUp, chunkSize) => {
    const results = [];
    while (arrayToBreakUp.length) {
        results.push(arrayToBreakUp.splice(0, chunkSize));
    }
    return results;
  }

  const initialState = {
    numberPagesShowing: 0,
    showingKeys: [],
    totalPages: 0,
  }
  const fileReducer = (state, action) => {
    switch (action.type) {
      case 'STORE':
        const pagedFiles = chunkArray(Object.keys(action.payload),10)
        return {
          ...state,
          pagedFiles: pagedFiles,
          numberPagesShowing: 1,
          showingKeys: createDateArray(1,pagedFiles),
          totalPages: pagedFiles.length,
        }
      case 'SHOW_NEXT_PAGE':
        return {
          ...state,
          numberPagesShowing: state.numberPagesShowing + 1,
          showingKeys: createDateArray(state.numberPagesShowing + 1, state.pagedFiles)
        }
      default:
        return state;
    }
  }

  const [datesConfig, dispatch] = useReducer(fileReducer, initialState)

  const showMore = () => {
    dispatch({type:"SHOW_NEXT_PAGE"})
  }

  const deleteFiles = () => {
    for( let f = 0; f<filesSelected.length; f+=1 ){
      globalDispatch({type:types.FILE_DELETE, payload: { file: filesSelected[f].id, takenDateAsKey: filesSelected[f].takenDateAsKey }})
    }
  }

  return { groupedFiles, datesConfig, showMore, filesSelected, deleteFiles }
}

export default useBrowse
