import * as types from '../store/types'
import { notification } from 'antd'

const notificationMiddleware = store => next => action => {
  if (action.type === types.SEND_NOTIFICATION) {
    if( action.payload.type === 'error') {
      notification.error({
        message: action.payload.message,
        description: action.payload.description,
        duration: 0,
        key: action.payload.key || null,
      })
    }
    else {
      notification.open({
        message: action.payload.message,
        description: action.payload.description,
        duration: action.payload.duration || 3,
        key: action.payload.key || null,
      })
    }
  
  }
  next(action);
}


export { 
  notificationMiddleware,
}