import React from 'react';
import {
  Redirect,
} from 'react-router-dom';
import cx from '../utils/styles'
import { useSelector } from 'react-redux';
import useNetlifyAuth from '../logic-hooks/useNetlifyAuth'

const Login = () => {
  const redirectTo = useSelector( state => state.user.redirectTo )
  const redirect = useSelector( state => state.user.redirect )
  const { login } = useNetlifyAuth()

  login()

  let from = redirectTo || '/' 
  if ( redirect ) return <Redirect to={from} />
  return (
    <div className={cx( ' ' )}>
      <button onClick={login}>Log in</button>
    </div>
  );
  
}

export default Login