import React from 'react';
import { DayForBrowse } from '../components';
import cx from '../utils/styles'
import _ from 'lodash'
import { Button, Affix, Spin } from 'antd'
import useBrowse from '../logic-hooks/useBrowse'
import renderIf from 'render-if'

const Browse = () => {
  
  const { groupedFiles, showMore, deleteFiles, datesConfig, filesSelected } = useBrowse()


  const createAlbum = () => {

  }
  const addToAlbum = () => {

  }

  return (
    <>
      <Affix offsetTop={ 50 }>
        <div className={cx(' container bg-green flex flex-wrap pv3 ')} >
          <Button onClick={ deleteFiles } >Delete</Button>
          <Button onClick={ createAlbum } >Add to new Album</Button>
          <Button onClick={ addToAlbum } >Add to existing Album</Button>
          { renderIf( filesSelected.length > 0 )(
          <div className={cx(' ml3 f2r white mt3 ')}>({ filesSelected.length }) selected</div>
          )}
        </div>
      </Affix>
      <div className={cx( ' container flex flex-column pt3 ' )} >
        { renderIf( datesConfig.numberPagesShowing === 0 )(
          <Spin className={cx(' mt5 ')} size="large" />
        )}
        {  datesConfig.numberPagesShowing > 0 && datesConfig.showingKeys.map( d => {
            return <DayForBrowse key={ d } date={ groupedFiles[d][0].takenDate } files={ groupedFiles[d] }/>
          })
        }
        { renderIf( datesConfig.numberPagesShowing > 0 && datesConfig.numberPagesShowing < datesConfig.totalPages )(
          <Button className={cx( ' mha mv4 ' )} onClick={ showMore } type="primary">Show more</Button>
        )}
      </div>
    </>
  );
}

export default Browse
