
import * as types from '../store/types'
const apiPath = '/.netlify/functions'
const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const lambdaMiddleware = store => next => action => {
  if (action.type === types.GET_YEARS) {
    fetch( apiPath + "/years-get" )
      .then(response => response.json())
      .then(json => {
        store.dispatch({ type: types.STORE_YEARS, payload: json})
        store.dispatch({ type: types.GET_YEAR_ALBUM_COUNT, payload: json})
      })
  }

  if (action.type === types.GET_YEAR_ALBUM_COUNT) {
    action.payload.forEach(p => {
      fetch( apiPath + `/albums-lite-get?year=${p.year}` )
        .then(response => response.json())
        .then(json => {
          store.dispatch({ type: types.STORE_YEAR_ALBUM_COUNT, payload: { year: p.year, albums: json }})
        })
    });
  }

  if (action.type === types.GET_YEAR_ALBUM_FILE_DETAILS) {
    fetch( apiPath + `/albums-files-get?year=${ action.payload }` )
      .then(response => response.json())
      .then(json => {
        store.dispatch({ type: types.STORE_YEAR_ALBUMS_FILES, payload: json })
      })
  }

  if (action.type === types.GET_ALBUM_FILES) {
    fetch( apiPath + `/album-files-get?name=${ encodeURI(action.payload.name) }&date=${ action.payload.date }` )
      .then(response => response.json())
      .then(json => {
        store.dispatch({ type: types.STORE_ALBUM_FILES, payload: json })
      })
  }
  
  if (action.type === types.GET_FILES_FROM_SEARCH) {
    let fetchData = { 
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( action.payload )
    }
    fetch( apiPath + `/filesForSearch-post`, fetchData )
      .then(response => response.json())
      .then(json => {
        store.dispatch({ type: types.STORE_SEARCH_FILES, payload: json })
      })
  }

  if (action.type === types.GET_ALL_ALBUM_NAMES) {
    let fetchData = { 
      method: 'GET', 
    }
    fetch( apiPath + `/album-names-get`, fetchData )
      .then(response => response.json())
      .then(json => {
        store.dispatch({ type: types.STORE_ALBUM_NAMES, payload: json })
      })
  }

  if (action.type === types.GET_ALL_PEOPLE_NAMES) {
    let fetchData = { 
      method: 'GET', 
    }
    fetch( apiPath + `/people-get`, fetchData )
      .then(response => response.json())
      .then(json => {
        store.dispatch({ type: types.STORE_ALL_PEOPLE_NAMES, payload: json })
      })
  }

  if (action.type === types.UPDATE_ALBUM && ( action.payload.new === undefined || action.payload.new === false )) {
      /*
      {
        action: name/date/files/all
        name: name,
        date: date,
        _id: string,
        files: [ mFiles ],
        cover: url
      }
      */

    let fetchData = { 
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( action.payload )
    }
    fetch( apiPath + `/album-update`, fetchData )
    .then(response => response.json())
    .then(json => {
      if( json.result === 'ok' ){
        store.dispatch({ type: types.UPDATED_ALBUM, payload: action.payload })
        store.dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'message', content: 'album updated' } }) 
      }
      else {
        store.dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'alert', content: 'album NOT updated' } })
      }
    })
  }

  if (action.type === types.SAVE_NEW_ALBUM ){
      /*
        {
          name: name,
          date: date,
          files: [ mFiles ]
        }
      */

     let fetchData = { 
      method: 'PUT', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( action.payload )
    }
    fetch( apiPath + `/album-put`, fetchData )
    .then(response => response.json())
    .then(json => {
      if( json.result === 'ok' ){
        store.dispatch({ type: types.NEW_ALBUM_SAVED, payload: action.payload })
        store.dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'message', content: 'album saved' } }) 
      }
      else {
        store.dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'alert', content: 'album NOT saved' } })
      }
    })
  }

  if (action.type === types.GET_FILES_FOR_BROWSE ){
    fetch( apiPath + `/flickr-get-photos` )
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if( json.result === 'ok' ){
        store.dispatch({ type: types.STORE_FILES_FOR_BROWSE, payload: json })
      }
      else {
        store.dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'alert', content: 'fail' } })
      }
    })
  }

  if (action.type === types.FILE_DELETE ){
    
    store.dispatch({ type: types.SEND_NOTIFICATION, payload: {
      message: `Deleting File`,
      description: action.payload.file,
      key: action.payload.file,
      type: 'normal'
    } })

    const deleteData = { 
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( { photoId: action.payload.file } )
    }
    fetch( apiPath + `/flickr-delete-photo`, deleteData )
    .then( handleErrors )
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if( json.result === 'ok' ){
        store.dispatch({ type: types.FILE_DELETED_SUCCESS, payload: action.payload })
      }
      else {
        // store.dispatch({ type: types.FILE_DELETED_FAIL, payload: action.payload })
        store.dispatch({ type: types.SEND_NOTIFICATION, payload: {
          message: `Failed to delete`,
          description: action.payload.file,
          key: action.payload.file,
          type: 'error'
        } })
      }
    })
    .catch( error => {
      store.dispatch({ type: types.SEND_NOTIFICATION, payload: {
        message: `Failed to delete -  ${error}`,
        description: action.payload.file,
        key: action.payload.file,
        type: 'error'
      } })
    })
  }

  next(action);
}

export { 
  lambdaMiddleware,
}