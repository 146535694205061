import React from 'react';
import { useSelector } from 'react-redux'
import cx from '../utils/styles'
import { useDispatch } from 'react-redux';
import * as types from '../store/types'
import { Menu, Dropdown } from 'antd'
import useReactRouter from 'use-react-router';
import useNetlifyAuth from '../logic-hooks/useNetlifyAuth'
import moment from 'moment'

/*
z-9999 to keep under Landbot popup
*/


const Header = () => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const mobileMenuIsOpen = useSelector( state => state.view.mobileMenuIsOpen )
  const { logout, login, isAdmin } = useNetlifyAuth()


  const onMenuClick = ({ key }) => {
    switch(key){
      case "home" : 
      history.push(`/`)
        break
      case "search" :
        dispatch({ type: types.TOGGLE_SEARCH});
        break
      case "login" :
        login()
        break
      case "logout" :
        logout()
        break
      // case "new_album" :
      //   if( !isAdmin ) {
      //     login()
      //     return
      //   }
      //   //create new album object. then got to normal album page
      //   const album = {
      //     name: "Untitled",
      //     date: new moment().format("YYYY-MM-DD"),
      //     cover: null,
      //     files: [ ],
      //     _id: "",
      //     new: true,
      //   }
      //   dispatch({type: types.ALBUM_SELECTED, payload: album})
      //   history.push(`/album/${album.date}-${album.name}`)
      //   break
      case "browse" : {
        if( !isAdmin ) {
          login()
          return
        }
        dispatch({type: types.GET_FILES_FOR_BROWSE})
        history.push(`/browse`)
        break
      }
      default :

    }
  };
  
  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="home">Browse Years</Menu.Item>
      <Menu.Item key="search">Search</Menu.Item>
      <Menu.Item key="browse">Browse Unsorted</Menu.Item>
      <Menu.Item key="login">Login</Menu.Item>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  const goHome = () => {
    history.push('/')
  }

  return (
    <header className={cx(`${ mobileMenuIsOpen ? 'bg-green' : 'bg-dark-grey'} w-100 overflow-y-hidden fixed top-0 z-5 trans ${ mobileMenuIsOpen ? 'h-100' : 'h-50px' } h-50px-ns`)}>
      <Dropdown className={cx('mt3')} overlay={menu} placement="bottomLeft" trigger={['click']}>
        <img className={cx('left-2 absolute pointer')} style={{height:'18px', top:'8px'}} src={ require(`../assets/menu${ mobileMenuIsOpen ? '-white' : '-white'}.svg`) }  title='menu' alt='icon' />
      </Dropdown>
      <img  onClick={goHome} className={cx('right-2 absolute pointer ')} style={{height:'18px', top:'16px'}} src={ require(`../assets/jaredsphotos-logo.png`) }  title='jp' alt='icon' />
    </header>
  )
}

export default Header;