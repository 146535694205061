import React from 'react';
import cx from '../utils/styles'
import * as types from '../store/types'
import { useDispatch } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link';
import moment from 'moment'

const Album = ({ name, date, files, cover, _id }) => {
  const dispatch = useDispatch();

  const selectAlbum = () => {
    dispatch({ type: types.ALBUM_SELECTED, payload: { name, date, files, _id, cover }});
  }

  return (
    <Link onClick={ selectAlbum } to={`/album/${date}-${name}`} key={name} className={cx( ' album trans relative w-100 w-33-m w-25-l pointer flex-column ' )} style={{height:'220px'}}>
      <div className={cx( ' cover h-100 w-100 ' )} style={{backgroundImage:`url(${cover})`}}/>
      <div className={cx( ' albumFade ' )} />
      <div className={cx( ' h-100 absolute top-2 left-2' )} >
        <div className={cx( ' green tl lh-copy f1-5r ' )} >{moment(date).format('MMM-DD')} </div>
        <div className={cx( ' white tl lh-copy f4r ff-Li ' )} >{name} </div>
      </div>
    </Link>
  );
}


export default Album