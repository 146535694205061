// import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../store/types';

const useAlbum = ( ) => {
  // const { history } = useReactRouter();
  const dispatch = useDispatch()
  // const [state, dispatch] = useReducer(reducer, initialState);
  const album = useSelector( state => state.view.displayAlbumWithFiles )

  // const initialState = {
  //   name: null,
  //   date: new moment().format("YYYY-MM-DD"),
  //   files: [],
  // }
  // const reducer = (state, action) => {
  //   switch (action.type) {
  //     case 'name': return { ...state,
  //       name: action.payload.name
  //     };
  //     case 'date': return { ...state,
  //       date: action.payload.date
  //     };
  //     case 'files': {
  //       return { ...state,
  //         files: action.payload.files
  //       }
  //     }
  //     default: throw new Error('Unexpected action');
  //   }
  // }

  const updateAlbum = ( updateBody ) => {
    dispatch({type: types.UPDATE_ALBUM, payload: updateBody})
  }

  const nameChanged = ( e ) => {
    if( e.target.value === ''){
      dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'alert', content: 'album hasn\'t got a name' } })
      return
    }
    if( e.target.value !== album.name ) {
      updateAlbum({
        action: 'name',
        name: e.target.value,
        _id: album._id,
        files: album.files.map( f => { return { ...f, album:e.target.value }}),
        cover: album.cover,
        date: album.date,
        new: album.new
      })
    }
  }

  const dateChanged = ( originalDate, newDate ) => {
    if( newDate === ''){
      dispatch({ type: types.SET_ALERT_OR_MESSAGE, payload: { type: 'alert', content: 'album hasn\'t got a name' } })
      return
    }
    updateAlbum({
      action: 'date',
      name: album.name,
      _id: album._id,
      files: album.files,
      cover: album.cover,
      date: newDate,
      new: album.new
    })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ( result ) => {
    if( !result.destination ) {
      return;
    }

    const items = reorder(
      album.files,
      result.source.index,
      result.destination.index
    );

    album.files = items.map( (i,index) => { return {...i,index_v2:index}})

    updateAlbum({
      action: 'files-index_v2',
      files: album.files,
      name: album.name,
      _id: album._id,
      cover: album.cover,
      date: album.date,
    })
  }

  const toggleReorderMode = () => {
    dispatch({type: types.SET_REORDER_MODE})
  }

  const save = () => {
    dispatch({ type: types.SAVE_NEW_ALBUM, payload: album })
  }

  return { toggleReorderMode, dateChanged, nameChanged, onDragEnd, album, save };
}

export default useAlbum