import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { message } from 'antd'
import * as types from '../store/types'
import { useDispatch } from 'react-redux'


const Alerts = () => {
  const dispatch = useDispatch()
  const alertOrMessage = useSelector( state => state.view.alertOrMessage )
  useEffect(() => {
    if( alertOrMessage === null ) return

    if( alertOrMessage.type === 'alert')
      message.error( alertOrMessage.content );

    if( alertOrMessage.type === 'message')
      message.success( alertOrMessage.content );

    dispatch({ type: types.UNSET_ALERT_OR_MESSAGE })
  }, [alertOrMessage]);

  return (
    null
  )
}

export default Alerts;