import * as types from '../store/types'
import netlifyIdentity from 'netlify-identity-widget';


const devUser = {
  user_metadata : {
    full_name: 'Dev USER'
  },
  app_metadata : {
    roles:['Admin']
  }
}

const netlifyAuthMiddleware = store => next => action => {
  if (action.type === types.IS_USER_AUTHED) {
    const user = process.env.NODE_ENV === 'development' ? devUser : netlifyIdentity.currentUser()
    if( user !== null )
      store.dispatch({ type: types.USER_AUTHED, payload: user });
  }
  if (action.type === types.NETLIFY_AUTH_INIT) {
    window.netlifyIdentity = netlifyIdentity;
    netlifyIdentity.init();
    store.dispatch({ type: types.IS_USER_AUTHED });
  }
  next(action);
}


export { 
  netlifyAuthMiddleware,
}