import React from 'react';
import { File } from '../components';
import cx from '../utils/styles'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';
import * as types from '../store/types'
import moment from 'moment'
import useNetlifyAuth from '../logic-hooks/useNetlifyAuth'
import renderIf from 'render-if'
import { Button, Input, DatePicker } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useAlbum from '../logic-hooks/useAlbum'

const dateFormat = 'YYYY-MM-DD';

const Album = () => {
  const dispatch = useDispatch();
  const albumNeedsSaving = useSelector( state => state.view.albumNeedsSaving )
  const { isAdmin } = useNetlifyAuth()
  const { location } = useReactRouter();
  const { dateChanged, nameChanged, onDragEnd, toggleReorderMode, album, save } = useAlbum()

  if( album == null ) {
    //send request to get files if page loaded here from link
    const date = location.pathname.split("/")[2].substr(0,10)
    const year = date.substr(0,4)
    const name = location.pathname.split("/")[2].substr(11)
    console.log('album container thinks albums is null', year)
    dispatch({ type: types.GET_YEAR_ALBUM_FILE_DETAILS, payload: year });
    dispatch({ type: types.GET_ALBUM_FILES, payload: { name, date }})
    return (
      null
    )
  }

  const TitleAndDate = () => {
    return (
      <>
        <div className={cx( ' flex mt3 mt6-ns mha' )}>
          { renderIf( isAdmin )(
            <Input onPressEnter={ nameChanged } onBlur={ nameChanged } className={cx('')} style={{ width:'206px' }} allowClear size="large" defaultValue={ album.name }  /> 
          )}
          { renderIf( !isAdmin )(
            <div className={cx( ' bg-grey f5r white tc lh-copy ' )} style={{ width:'370px' }}>{album.name}</div>
          )}
        </div>
        { renderIf( isAdmin )(
          <DatePicker onChange={ dateChanged } className={cx( 'w-23 mha' )} allowClear={false} size="large" defaultValue={moment( album.date )} format={ dateFormat } />
        )}
        { renderIf( !isAdmin )(
          <div className={cx( ' bg-grey f3r green tc lh-title ' )}>{moment(album.date).format(dateFormat)}</div>
        )}
      </>
    )
  }
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '',
  
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '',
  });

  return (
    <div className={cx( ' flex flex-column ' )}>
      { renderIf( isAdmin )(
        <Button onClick={ toggleReorderMode }>Re Order</Button>
      )}
      { renderIf( isAdmin && albumNeedsSaving )(
        <Button onClick={ save }>Save</Button>
      )}
      <TitleAndDate/>
      <DragDropContext onDragEnd={ onDragEnd }>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className={cx( ' bg-grey horizontal-scroll-wrapper flex mt3 flex-nowrap overflow-x-auto flex-column flex-row-ns ' )}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {album.files && album.files.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <File file={ item }/>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default Album