import { Home, Year, Album, Search, BrowseAllConent, Login } from './containers'
// import { withAuthentication, lazyLoad } from "../views/enhancers";

const routes = [
    {
        path: "/",
        component: Home,
        exact: true,
        private: false,
    },
    {
        path: "/year/:year",
        example: "/years/2010",
        component: Year,
        exact: true,
        private: false,
    },
    {
        path: "/album/:album",
        example: "/album/2010-01-01-Waipa",
        component: Album,
        exact: true,
        private: false,
    },
    {
        path: "/search",
        // example: "/search?",
        component: Search,
        exact: true,
        private: false,
    },
    {
        path: "/login",
        component: Login,
        exact: true,
        private: false,
    },
    {
        path: "/browse",
        component: BrowseAllConent,
        exact: true,
        private: true,
    },
    // {
    //     path: "/products/:permalink",
    //     example: "/products/apple",
    //     component: ProductDetails,
    //     exact: true,
    // },
    // {
    //     path: "/cart",
    //     component: lazyLoad( ( ) => import( "../views/pages/cart" ) ),
    //     exact: true,
    // },
    // {
    //     path: "/myaccount",
    //     component: withAuthentication( lazyLoad( ( ) => import( "../views/pages/myAccount" ) ) ),
    //     exact: true,
    // },
];

export default routes;