import React from 'react';
import cx from '../utils/styles'
import { useSelector } from 'react-redux'
import Vimeo from '@u-wave/react-vimeo';
import { isMobile } from 'react-device-detect';
import { Button} from 'antd'
import download from 'downloadjs'

const File = ({ file }) => {
  const reorderMode = useSelector( state => state.view.reorderMode )

  const fileHeight = reorderMode === true ? '60px' : window.innerHeight / 2

  const selectFile = () => {
    // dispatch({ type: types.ALBUM_SELECTED, payload: { name, date, files }});
  }

  /*
  {
    id:mFile._id,
    urlOrig: mFile.flickrOrigUrl,
    url: mFile.flickrWsUrl,
    taken: mFile.taken,
    album: mAlbum.name,
    albumDate: moment( mAlbum.albumDate ).format('YYYY-MM-DD'), 
    filename: mFile.filename,
    wsWidth: mFile.width,
    wsHeight: mFile.height,
    label:mFile.label,
    vimeoID:mFile.VimeoID,
}
*/

  const Image = () => {
    if( file.fileType !== 'jpg') return null

    const dl = () => {
      var x=new XMLHttpRequest();
      x.open( "GET", file.urlOrig , true);
      x.responseType="blob";
      x.onload= function(e){download(e.target.response, `${file.album}-${file.filename.trim()}`  , "image/jpg");};
      x.send();
    }
    
    const label = file.label !== undefined ? `${file.album}-${file.label}` : file.album

    return (
      <>
        <div className={cx( ' ba lh-0 b-light-grey ' )}> 
          <img className={cx( 'w-auto ' )} style={{height: isMobile ? 'auto' : fileHeight, width: isMobile ? '100%' : 'auto'}} src={ file.url }  title={ '' } alt={ '' } />
        </div>
        <div className={cx( ' light-silver f1r mt2 lh-title ' )}> { label } </div>
        <Button onClick={ dl } className={cx( ' dn db-ns fr file-dl ' )} type="primary" shape="circle" icon="download" style={{ top: -40,right: 8}} />
      </>
    )
  }

  const Video = () => {
    if( file.fileType === 'jpg') return null

    return (
      <Vimeo
        video={file.vimeoID}
        height={ isMobile ? 'auto' : fileHeight }
        width={ isMobile ? window.innerWidth-16 : 'auto' }
      />
    )
  }

  

  return (
    <div onClick={ selectFile } className={cx( ' relative pointer flex-column mh3 mv2 mv1-ns lh-0' )}>
      <Image/>
      <Video/>
    </div>
  );
}


export default File